import thumb1 from "../assets/image/png/portfolio-covers/portfolio-1.jpg";
import thumb2 from "../assets/image/png/portfolio-covers/portfolio-2.jpg";
import thumb3 from "../assets/image/png/portfolio-covers/portfolio-3.jpg";
import thumb4 from "../assets/image/png/portfolio-covers/portfolio-4.jpg";
import thumb5 from "../assets/image/png/portfolio-covers/portfolio-5.jpg";
import thumb6 from "../assets/image/png/portfolio-covers/portfolio-6.jpg";
import thumb7 from "../assets/image/png/portfolio-covers/portfolio-7.jpg";

const images1 = {
  coverImage: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F1%2Fcover.jpg?alt=media&token=bb2d02b2-471c-4821-9d41-094a90fb6135",
  img1: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F1%2Fimg1.jpg?alt=media&token=04f85a88-0b97-4099-9b9e-df481ef26a38",
  img2: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F1%2Fimg2.jpg?alt=media&token=4f4dc8b6-41e9-4a6e-935f-a8b6c9368c1b",
};
const images2 = {
  coverImage: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F2%2Fcover.jpg?alt=media&token=f8bf1a29-3fab-416b-afdd-0e35f49ca9e4",
  img1: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F2%2Fimg1.jpg?alt=media&token=d0a742fe-bdee-4197-a90c-1c459edf37bc",
  img2: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F2%2Fimg2.jpg?alt=media&token=fda296b5-d4a8-40b6-957b-cf790e71ea2b",
  img3: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F2%2Fimg3.jpg?alt=media&token=e18d080d-76be-45f5-823c-9e93737ca92d",
};
const images3 = {
  coverImage: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F3%2Fcover.jpg?alt=media&token=aaee9305-7c18-45c5-a9b6-6efc22ce5891",
  img1: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F3%2Fimg1.jpg?alt=media&token=a6d26007-6f7d-449e-9c72-c4ac2ad616e1",
  img2: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F3%2Fimg2.jpg?alt=media&token=1e77d7e0-46bb-49f0-bd22-519cafd02a40",
};
const images4 = {
  coverImage: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F4%2Fcover.PNG?alt=media&token=c467a374-bd0e-4bf5-be5f-e559fcdacba8",
  img1: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F4%2Fimg1.jpg?alt=media&token=afc26e80-9e2b-4c09-a098-070c8b95df8a",
  img2: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F4%2Fimg2.jpg?alt=media&token=3bb57e7b-5547-4dc0-b3b7-cdeb0b5e650f",
  img3: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F4%2Fimg3.jpg?alt=media&token=32c47fd0-cf1f-401c-aed4-e65257fef358",
};
const images5 = {
  coverImage: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F5%2Fcover.PNG?alt=media&token=f84e889a-2ce8-4069-be95-9121fc9b3b01",
  img1: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F5%2Fimg1.jpg?alt=media&token=b81f1456-b8ce-406b-ad5e-853e67d1b249",
  img2: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F5%2Fimg2.jpg?alt=media&token=89972fb1-d21e-4bdb-84e9-79aaca2b3db0",
  img3: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F5%2Fimg3.jpg?alt=media&token=341609eb-93f5-462d-b240-0ce3db0ddbef",
};
const images6 = {
  coverImage: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F6%2Fcover.jpg?alt=media&token=d03d14db-db9d-49b7-b2e0-b60c38bdc5c9",
  img1: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F6%2Fimg1.jpg?alt=media&token=fff96483-18a1-4a96-a676-5c999cf39951",
  img2: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F6%2Fimg2.jpg?alt=media&token=723632d8-3ce0-4530-944f-4b466632e93c",
  img3: "https://firebasestorage.googleapis.com/v0/b/mulithajayawardanacom.appspot.com/o/portfolio-details%2F6%2Fimg3.jpg?alt=media&token=e22042e8-2362-48e1-bfe4-3408b38d534d",
};

export const recentWorks = [
  {
    id: 1,
    brand: `Chat App Project`,
    slug: `chat-app-project`,
    title: `Web-based responsive chat application for university`,
    categories: [`web`],
    thumbnail: thumb1,
    link: "/portfolio-details",
    description: `Due to the Covid pandemic situation, almost every student had to continue their studies remotely. A significant increase in the proportion of online students, become extremely challenging to maintain the quality of education. The Client’s main intention was to bridge the gap between the lecture and the student. So, we come across a sustainable solution, that helps students to interact with their lectures virtually and discuss their problems based on the module.
    Simply they can log into the system through their official email address. Application is directly integrated with their main EPR system. So, students/lecturers do not need to pre-configure anything, even the lecture, modular, and students who participated in to that particular module.  
    `,
    coverImage: images1.coverImage,
    client: "Canadian",
    time: "2021",
    liveWorkUrl: "",
    technologies: [
      "Angular",
      "Nodejs",
      "MongoDB",
      "AWS S3 bucket",
      "AWS hosting",
    ],
    images: [images1.img1, images1.img2],
    isActive: true,
  },
  {
    id: 4,
    brand: `Restaurant Reservation Project`,
    slug: `restaurant-reservation-project`,
    title: `SaaS-based restaurant reservation mobile app for both Android & IOS`,
    categories: [`app`, `ui-design`],
    thumbnail: thumb4,
    link: "/portfolio-details",
    description: `Dubai has more than 13,000 restaurants & cafes, with approximately 269 people per restaurant each day. This has resulted in large queues and a cumbersome environment in almost every restaurant. The Client’s main intention was to solve the gap between the consumer and the seller while providing a smooth transition from ordering to delivery. So, we come across a smart solution, that will help to boost sales of businessmen and correspondently consumer gets a chance to experience a variety of foods in a particular area without waiting in a queue. Initially, the App targets foreigners who will be struggling to choose a good restaurant for a reasonable price based on their perception. But right now, the application rocked the entire Dubai.`,
    coverImage: images4.coverImage,
    client: "Dubai",
    time: "2021-2022",
    liveWorkUrl: "",
    technologies: ["Flutter", "Laravel", "Firebase", "Postgresql"],
    images: [images4.img1, images4.img2, images4.img3],
    isActive: true,
  },
  {
    id: 2,
    brand: `Better World Project`,
    slug: `better-world-project`,
    title: `Seperate platform to control plution`,
    categories: [`web`],
    thumbnail: thumb2,
    link: "/portfolio-details",
    description: `Non-Profitable public research project that was implemented to Reduce Marine Pollution in Sri Lanka. Hence country has become the top five marine plastic polluters of the world on Word Earth Day 2019. My main intention was to preside a sustainable solution to control pollution in our country. So, we come across a smart solution, which benefits to all contributors. We implemented a separate platform while connecting national authorities, sponsors, and public users to start projects over the country and each party will be received different kinds of benefits based on the project. Selected people under different categories, will be receiving national recognition each year through the price-giving ceremony.`,
    coverImage: images2.coverImage,
    client: "Own Project",
    time: "2021",
    liveWorkUrl: "",
    technologies: ["Angular", "Flutter", "Firebase"],
    images: [images2.img1, images2.img2, images2.img3],
    isActive: true,
  },
  {
    id: 5,
    brand: `Sales Mate Project`,
    slug: `sales-mate-project`,
    title: `Increase sells immeasurably, while introducing products to the market new in someway`,
    categories: [`app`, `ui-design`],
    thumbnail: thumb5,
    link: "/portfolio-details",
    description: `The company noted a downward trend in introducing newly created products to retailers & customers. The lack of communication between retailers, poor transparency, and distributor marketing chaos have become major concerns in the loss of great deals. The client’s main intention was to build trust and interconnection between retailers and distributors again. So, we implemented a separate platform that retailers have self-access to company products before buying them. This will help to get a clear understanding about the product and how it will succeed in a particular area based on the generated analytic stats.`,
    coverImage: images5.coverImage,
    client: "Sri Lankan",
    time: "2022",
    liveWorkUrl: "",
    technologies: ["Flutter", "Firebase"],
    images: [images5.img1, images5.img2, images5.img3],
    isActive: true,
  },
  {
    id: 3,
    brand: `Frelance Platform`,
    slug: `frelance-platform`,
    title: `Web-based responsive freelance platform`,
    categories: [`web`, `ui-design`],
    thumbnail: thumb3,
    link: "/portfolio-details",
    description: `Based on the client’s requirement we implemented a creative, user-friendly freelance platform that mainly offers services in fashion & design areas. However, the system is already ready for a large number of categories for further expansion.`,
    coverImage: images3.coverImage,
    client: "Belgium",
    time: "2021",
    liveWorkUrl: "",
    technologies: ["Angular", "NodeJs", "MySql"],
    images: [images3.img1, images3.img2],
    isActive: true,
  },

  {
    id: 6,
    brand: `Food Delivery Project`,
    slug: `food-relivery-project`,
    title: ``,
    categories: [`app`, `ui-design`],
    thumbnail: thumb6,
    link: "/portfolio-details",
    description: ``,
    coverImage: images6.coverImage,
    client: "",
    time: "",
    liveWorkUrl: "",
    technologies: ["Flutter", "Firebase"],
    images: [images6.img1, images6.img2, images6.img3],
    isActive: false,
  },
  {
    id: 7,
    brand: `Fitness Project`,
    slug: `fitness-project`,
    title: ``,
    categories: [`app`, `ui-design`],
    thumbnail: thumb7,
    link: "/portfolio-details",
    description: ``,
    coverImage: ``,
    client: "",
    time: "",
    liveWorkUrl: "",
    technologies: [],
    images: [],
    isActive: false,
  },
];
