import React, { useEffect, useState, View } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import styled, { ThemeProvider } from "styled-components";

import { device } from "../utils";
import { recentWorks } from "../data";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

const WorkSingle = (props) => {
  const activeItems = recentWorks.filter(x=>x.isActive);
  const [item, setItem] = useState(null);

  useEffect(() => {
    const id = (props?.location?.state?.id)? props.location.state.id: 1;
    const item = activeItems.find((x) => x.id == id);
    setItem(item);
  }, []);

  const getNextItem = (index) => {
    if (index <= 0 || !activeItems[index]) return activeItems[0];
    else return activeItems[index];
  };

  const getNextProject = () => {
    return (
      <>
        <Text variant="tag" className="mb-1" color="lightShade">
          Next Project
        </Text>
        <Button
          onClick={() => {
            window.scrollTo({top:0, behavior: "smooth"});
            let currentItemIndex = activeItems.findIndex((x) => x.id == item.id);
            let nextItem = getNextItem(currentItemIndex + 1);
            setItem(nextItem);
          }}
          arrowRight
          className="border-0 bg-transparent shadow-none text-capitalize py-3"
          css={`
        font-weight: 700
        font-size: 1.5rem;
        letter-spacing: -1.2px;
        line-height: 1.375;
        @media ${device.md} {
          font-size: 2rem;
        }
    `}
        >
          Replace
        </Button>
      </>
    );
  };



  return (
    <>
      {item && (
        <PageWrapper>
          <Container
            fluid
            className="px-sm-5"
            css={`
              margin-top: 92px;
            `}
          >
            <img src={item?.coverImage} alt="" className="img-fluid w-100" />
          </Container>

          <div className="mt-lg-5">
            <Container>
              <Row>
                <Col lg="8">
                  <Text variant="tag">{item?.brand}</Text>
                  <Title variant="secSm" className="my-4">
                    {item?.title}
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    {item?.description}
                  </Text>
                </Col>
                <Col xs="12">
                  <Text
                    variant="p"
                    css={`
                      margin-top: 25px;
                      margin-bottom: 10px;
                    `}
                  >
                    Technologies
                  </Text>
                  <ul>
                    {item?.technologies?.map((x, index) => (
                      <li key={index}>{x}</li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="mt-lg-5">
            <Container>
              <Row>
                <Col lg="4" className="mb-4 mb-lg-0">
                  <Text variant="tag">Client</Text>
                  <Title variant="cardBig" className="mt-3">
                    {item?.client}
                  </Title>
                </Col>
                <Col lg="4" className="mb-4 mb-lg-0">
                  <Text variant="tag">Time</Text>
                  <Title variant="cardBig" className="mt-3">
                    {item?.time}
                  </Title>
                </Col>
                {item?.liveWorkUrl != "" ? (
                  <Col lg="4">
                    <Button
                      arrowRight
                      onClick={() => {
                        window.open(item.liveWorkUrl, "_blank");
                      }}
                    >
                      Live work
                    </Button>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Container>
          </div>

          <Section mt="24%" bg="dark">
            <Container>
              <Row
                css={`
                  margin-top: -40%;
                `}
              >
                <Col xs="12" className="mb-5">
                  <img
                    src={item.images?.length > 0 ? item.images[0] : ""}
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
                <Col xs="12" className="mb-5">
                  <img
                    src={item.images?.length > 0 ? item.images[1] : ""}
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
              </Row>
              <div className="text-center mt-lg-5">
                <div>{getNextProject()}</div>
              </div>
            </Container>
          </Section>
          <Box py={4}>
            <Contact />
          </Box>
        </PageWrapper>
      )}
    </>
  );
};
export default WorkSingle;
